import axios from 'axios';
import { useUserStore } from '@/store/userInfo';
import {storeToRefs} from 'pinia'
export const checkToken = () => {
    const userStore = useUserStore()
    const{token , expire_time} = userStore.loadTokenAndExpire()
    if (!token){
        return { isValid: false, token: '' }
    }
    if (token == ''){
      return { isValid: false, token: '' };
    }
    // 检查 token 是否过期
    if (Date.now()/1000 > expire_time) {
      //重新登陆
      return { isValid: false, token: '' };
    }
    return { isValid: true, token: token };
}

export const noticeSet = (isNotice, noticeToast, mes) => {
    isNotice.value = true;
    noticeToast.value = mes;
    setTimeout(() => {
        isNotice.value = false;
    }, 3000);
};

export const getUserInfo = async() =>{
  const userStore = useUserStore()
  userStore.loadUserInfo()
  const {userPhoneNumber} = storeToRefs(userStore)
  const {isValid ,token} =  checkToken()
  console.log('isValid',isValid)
    if (!isValid){
      console.log('crear')
      localStorage.clear()
      return 'token无效，请重新登陆'
    }
    try {
      const response = await axios.get('https://www.henangaokao.love/kun-peng-ai/user-info',{
        headers:{
          'token':token
        }
      })
      const data = response.data.data
      const code = response.data.code
      console.log('data',data)
      if (code !=0 ) {
        console.log('crear222')
        localStorage.clear()
        return '用户身份信息错误,请重新登陆'
      }
      if (userPhoneNumber.value == ''){
        userStore.setUserInfo(data.phone_number, true)
      }
    }catch(error){
      console.log('crear333')
      localStorage.clear()
      return '获取用户详情失败,请联系管理员'
    }
  }
