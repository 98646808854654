import { createRouter, createWebHistory } from "vue-router";

import UserLogin from '@/views/UserLogin.vue'
import UserQuit from '@/views/UserQuit.vue'
import MyAi from '@/views/MyAi.vue'
import UserTalk from '@/views/UserTalk.vue'
import DesktopMyAi from '@/views/DesktopMyAi.vue'
import DesktopUserTalk from '@/views/DesktopUserTalk.vue'

// 检测设备类型
const isMobile = () => {
  return window.innerWidth <= 768;
};

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            redirect: '/talk'
        },
        {
            path: '/login',
            component: UserLogin
        },
        {
            path: '/quit',
            component: UserQuit
        },
        {
            path: '/my-ai',
            component: () => {
                return Promise.resolve(isMobile() ? MyAi : DesktopMyAi);
            }
        },
        {
            path: '/talk',
            component: () => {
                return Promise.resolve(isMobile() ? UserTalk : DesktopUserTalk);
            }
        }
    ]
});

export default router;
