import { defineStore } from 'pinia';

export const useUserStore = defineStore('user' ,{
    state: () => ({
        token:'',
        expire_time:'',
        isThinkingAgents:{},
        isTalkingAgents:{},
        userPhoneNumber:'',
        whetherLogin:false
    }),
    actions:{
        addThinkingAgent(agentId) {
            this.isThinkingAgents[agentId] = true
            this.saveToLocalStorage()
        },
        rmThinkingAgent(agentId){
            if (this.isThinkingAgents[agentId]){
                delete(this.isThinkingAgents[agentId])
                this.saveToLocalStorage()
            }
        },
        checkThinkingAgent(agentId){
            if (this.isThinkingAgents[agentId]){
                return {isThinking:this.isThinkingAgents[agentId]}
            }else{
                return {isThinking:false}
            }
        },
        addTalkingAgent(agentId) {
            this.isTalkingAgents[agentId] = true
            this.saveToLocalStorage()
        },

        rmTalkingAgent(agentId){
            if (this.isTalkingAgents[agentId]){
                delete(this.isTalkingAgents[agentId])
                this.saveToLocalStorage()
            }
        },
        checkTalkingAgent(agentId){
            if (this.isTalkingAgents[agentId]){
                return {isTalking:this.isTalkingAgents[agentId]}
            }else{
                return {isTalking:false}
            }
        },
        logout(){
            localStorage.removeItem('token'); // 清除存储
            localStorage.removeItem('userStore')
        },
        saveToLocalStorage(){
            localStorage.setItem('userStore' , JSON.stringify({
                isThinkingAgents:this.isThinkingAgents,
                isTalkingAgents:this.isTalkingAgents,
            }))
        },
        loadToLocalStorage(){
            const storeData = localStorage.getItem('userStore')
            if (storeData){
                this.$patch(JSON.parse(storeData))
                return {isThinkingAgents:this.isThinkingAgents ,isTalkingAgents:this.isTalkingAgents}
            }
        },
        saveTokenAndExpire(token,expire_time){
            this.token = token
            this.expire_time = expire_time
            localStorage.setItem('token' , JSON.stringify({
                token:this.token,
                expire_time:this.expire_time
            }))
        },
        loadTokenAndExpire(){
            const storeData = localStorage.getItem('token')
            if (storeData){
                const parsedData = JSON.parse(storeData)
                this.$patch(parsedData)
                return {token:parsedData.token ,expire_time:parsedData.expire_time}
            }
            return {token:'' ,expire_time:''}
        },
        setUserInfo(phoneNumber, isLoggedIn) {
            this.userPhoneNumber = phoneNumber
            this.whetherLogin = isLoggedIn
            localStorage.setItem('userInfo', JSON.stringify({
                userPhoneNumber: this.userPhoneNumber,
                whetherLogin: this.whetherLogin
            }))
        },
        loadUserInfo(){
            const storeData = localStorage.getItem('userInfo')
            if (storeData){
                const parsedData = JSON.parse(storeData)
                this.$patch(parsedData)
                return {userPhoneNumber:parsedData.userPhoneNumber ,whetherLogin:parsedData.whetherLogin}
            }
            return {userPhoneNumber:'',whetherLogin:false}
        }
    }
})