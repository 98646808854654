<template>
  <div class="index">
    <transition name="fade">
        <div v-if="isErrorToast" class="error-toast">
        <div class="wrong">
          <img src="../assets/icon/wrong.png" alt="" class="wrong-img">
        </div>
        <div class="worong-msg-div">
          <p class="worong-msg">{{ errorToast }}</p>
        </div>
      </div>
      </transition>
      <transition name="fade">
        <div v-if="isNotice" class="error-toast">
        <div class="wrong">
          <img src="../assets/icon/notice.png" alt="" class="wrong-img">
        </div>
        <div class="worong-msg-div">
          <p class="worong-msg">{{ noticeToast }}</p>
        </div>
      </div>
    </transition>
    <div class="phone" v-if="!historyListOpen">

      <!-- 测试语音输入 -->
        <!-- <button @click="startVoiceInput()" class="user-input-speack" v-if="!voiceInpuing"></button>
        <button @click="stopVoiceInput()" class="user-input-speack-stop" v-else></button>  -->

      <div class="overlay" v-if="openMenu" @click="optionMenu()"></div>
      <div class="overlay2" v-if="outPage || loginPage" @click="closeLoginPage"></div>
      <div class="out-page" v-if="outPage">
        <div class="out-page-title">
          <p>确认退出登录?</p>
        </div>
        <div class="out-page-buttons">
          <div class="cancle" @click="cancle()">
            <p>取消</p>
          </div>
          <div class="yes" @click="signOutSure()">
            <p>确认</p>
          </div>
        </div>
      </div>
      <div class="login-page" v-if="loginPage">
        <div class="close" @click="closeLoginPage()">
            <img src="../assets/icon/close.png" alt="" class="close-icon">
        </div>
        <div class="login-page-head">
          <p class="login-page-title">账号登陆</p>
        </div>
        <div class="login-page-user-id">
          <input type="text" class="user-id" placeholder="请输入账号" v-model="userInputId">
        </div>
        <div class="login-page-user-password">
          <div class="password-input-container">
            <input :type="showPassword ? 'text' : 'password'" 
                   class="user-password" 
                   placeholder="请输入密码" 
                   v-model="userInputPassword">
            <div class="password-toggle" @click="togglePasswordVisibility">
              <img :src="showPassword ? require('../assets/icon/eye-open.png') : require('../assets/icon/eye-close.png')" 
                   alt="toggle password" 
                   class="eye-icon">
            </div>
          </div>
        </div>
          <button class="login-page-login" @click="loginSure()">登陆</button>
      </div>
      <div class="head">
        <div class="menu-button"  @click="optionMenu()">
          <img src="../assets/icon/展开.png" alt="展开菜单" class="menu">
        </div>
        <transition name="slide" appear>
          <div v-if="openMenu" class="menu-list">
            <div @click="optionMenu()" class="close-menu">
              <img src="../assets/icon/收起.png" alt="关闭菜单" class="close-menu-img">
            </div>
            <div class="tabs">
              <div class="tab" 
                   :class="{ 'tab-active': isActiveRoute('talk') }" 
                   @click="navigateTo('talk')">
                  <div class="tab-icon">
                    <img src="../assets/logo/indexLogo.png" alt="">
                  </div>
                  <div class="tab-name">
                    <p>鲲鹏2.0对话助手</p>
                  </div>
              </div>
              <div class="tab" 
                   :class="{ 'tab-active': isActiveRoute('talk', '520') }" 
                   @click="navigateTo('talk','520')">
                  <div class="tab-icon">
                    <img :src="getAgentImage('520')" alt="">
                  </div>
                  <div class="tab-name">
                    <p>知心姐姐</p>
                  </div>
              </div>
              <div class="tab" 
                   :class="{ 'tab-active': isActiveRoute('talk', '666') }" 
                   @click="navigateTo('talk','666')">
                  <div class="tab-icon">
                    <img :src="getAgentImage('666')" alt="">
                  </div>
                  <div class="tab-name">
                    <p>未来规划师</p>
                  </div>
              </div>
              <div class="tab" 
                   :class="{ 'tab-active': isActiveRoute('talk', '888') }" 
                   @click="navigateTo('talk','888')">
                  <div class="tab-icon">
                    <img :src="getAgentImage('888')" alt="">
                  </div>
                  <div class="tab-name">
                    <p>法律顾问</p>
                  </div>
              </div>
              <div class="tab" 
                   :class="{ 'tab-active': isActiveRoute('talk', '999') }" 
                   @click="navigateTo('talk','999')">
                  <div class="tab-icon">
                    <img :src="getAgentImage('999')" alt="">
                  </div>
                  <div class="tab-name">
                    <p>模拟大学</p>
                  </div>
              </div>
              <div class="tab" 
                   :class="{ 'tab-active': isActiveRoute('my-ai') }" 
                   @click="navigateTo('my-ai')">
                  <div class="tab-icon">
                    <img src="../assets/agent_image/智能教育机器中心.png" alt="">
                  </div>
                  <div class="tab-name">
                    <p>智教智能体中心</p>
                  </div>
              </div>
            </div>
            <div class="my-center">
                <img src="../assets/user_image.png" alt="" class="my-image">
              <div class="phone-number">
                <p>{{ userPhoneNumber }}</p>
              </div>
              <div class="login">
                <p v-if="whetherLogin" @click="signOut()">退出登陆</p>
                <p v-else @click="login()">登陆</p>
              </div>
            </div>
          </div>
        </transition>
        <div class="logo-2" v-if="!isNewPage">
          <img :src="getAgentImage(agentId)" alt="" class="logo-2-image">
          <p class="logo-2-name">{{agent_name}}</p>
        </div>
        <div class="talk-icon">
          <div class="new-talk" @click="createNewTalk()" data-tooltip="新建对话">
            <img src="../assets/icon/new.png" alt="" class="new-talk-icon">
          </div>
          <div class="get-history-list" @click="getChatHistoryList()" data-tooltip="历史记录">
            <img src="../assets/icon/chatHistory2.png" alt="" class="new-talk-icon">
          </div>
        </div>
      </div>
      <div v-if="isNewPage">
        <div class="logo">
          <img :src="getAgentImage(agentId)" alt="智能体图片" class="my-ai-img">
          <!-- <img src="../assets/logo/indexLogo.png" alt="" class="logo"> -->
        </div>
        <div class="title">
          <p class="name">{{agent_name}}</p>
          <p class="creater">创建人 @{{ agent_creator }}</p>
        </div>
        <div class="desc">
          <p class="agent_desc">{{agent_desc}}</p>
        </div>
        <!-- <div class="welcome-tip">
          <p>欢迎使用，请输入您的问题</p>
        </div> -->
      </div>
      <div class="talk-content" ref="talkContent" @scroll="handleScroll()" >
        <div v-for="(message , index) in thisChatHistory" :key="index" :class="message.role">
          <p class="message" v-html="renderMarkdown(message.content)"></p>
          <div class="message-bottom-select" v-if="message.role == 'ai' && message.content_id && !message.isGenerating">
            <div class="play-video" @click="optionPlayVideo(message.content_id)">
              <img src="../assets/icon/playingVideo.png" alt="播放" class="play-video-icon" v-if="getPlayVideoStatus(message.content_id)">
              <img src="../assets/icon/playVideo.png" alt="播放" class="play-video-icon" v-else>
              <p v-if="getPlayVideoStatus(message.content_id)">播放中</p>
              <p v-else>播放</p>
            </div>
            <div class="copy-text" @click="copyText(message.content)">
              <img src="../assets/icon/copy.png" alt="复制" class="copy-icon">
              <p>复制</p>
            </div>
          </div>
        </div>
        <div v-if="isThinkingAgents[agentId]" class="ai">
          <p class="message">思考中<span class="dot">.</span><span class="dot">.</span><span class="dot">.</span></p>
        </div>
      </div>
      <div class="bottom">
        <div class="send-content">
          <input 
            type="text" 
            :disabled="!whetherLogin || isTalkingAgents[agentId]" 
            v-model="userMessage" 
            @keyup.enter="sendMessage()" 
            class="input-text" 
            :placeholder="whetherLogin ? '输入消息...' : '登录后可以输入消息...'"
          >
          <button 
            :disabled="!whetherLogin || isTalkingAgents[agentId]" 
            class="send-button" 
            v-if="userMessage === ''"
          >
            <img src="../assets/icon/send.png" alt="" class="send-icon">
          </button>
          <button 
            :disabled="!whetherLogin || isTalkingAgents[agentId]" 
            @click="sendMessage()" 
            class="can-send-button" 
            v-else
          >
            <img src="../assets/icon/send.png" alt="" class="send-icon">
          </button>
        </div>
        <div class="notice">
          <p>内容由AI生成，请注意辨别</p>
        </div>
      </div>
    </div>
    <div v-else class="history-list">
      <div class="history-list-head">
        <div class="back-icon" @click="closeHistoryList()">
          <img src="../assets/icon/back.png" alt="���回" class="back-icon-image">
        </div>
        <div class="history-list-title">
          <p class="history-list-title-p">历史聊天记录</p>
        </div>
        <p class="history-list-head-right">
        </p>
      </div>
      <div class="history-list-body" @scroll="handleHistoryScroll">
        <!-- 有历史记录时显示列表 -->
        <template v-if="chat_conversion_list && chat_conversion_list.length > 0">
          <div v-for="(chat, index) in chat_conversion_list" 
               :key="index" 
               class="history-info"
               @click="getChatDetail(chat.conversion_id, 1)">
            <div class="history-info-word">
              <p class="history-desc">{{ chat.talk_desc || '新对话' }}</p>
              <p class="history-time">{{ chat.talk_time }}</p>
            </div>
            <div class="history-delete" @click.stop="deleteChatHistory($event, chat.conversion_id)">
              <img src="../assets/icon/delete.png" alt="删除">
            </div>
          </div>
          
          <!-- 添加加载状态提示 -->
          <div v-if="historyLoading" class="loading-more">
            <p>加载中...</p>
          </div>
        </template>
        
        <!-- 无历史记录时显示空状态 -->
        <div v-else class="empty-history">
          <p>暂无历史对话记录</p>
        </div>
      </div>
      <!-- 添加删除确认弹窗 -->
      <div class="overlay2" v-if="deleteConfirmVisible" @click="cancelDelete"></div>
      <div class="delete-confirm" v-if="deleteConfirmVisible">
        <div class="delete-confirm-title">
          <p>确认删除该对话记录？</p>
        </div>
        <div class="delete-confirm-buttons">
          <div class="cancel-button" @click="cancelDelete">
            <p>取消</p>
          </div>
          <div class="confirm-button" @click="confirmDelete">
            <p>确认</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  import { useUserStore } from '@/store/userInfo';
  import {storeToRefs} from 'pinia'
  import axios from 'axios';
  import {nextTick, ref ,onMounted,onBeforeUnmount, onBeforeMount} from 'vue';
  import { useRoute ,useRouter} from 'vue-router';
  import { marked } from 'marked';
  import {checkToken,noticeSet,getUserInfo} from '@/utils/common'

  // 在 script 部分导入默认图片
  import defaultAgentImage from '../assets/logo/indexLogo.png'  // 默认图片路径

  export default {
    name: 'UserIndex',
    setup(){
      const userStore = useUserStore()
      userStore.loadToLocalStorage()
      userStore.loadUserInfo()
      const { userPhoneNumber,whetherLogin,isThinkingAgents, isTalkingAgents } = storeToRefs(userStore)
      const loginPage = ref(false)
      const outPage = ref(false)
      const userMessage = ref('')
      const openMenu = ref(false)
      const userInputId = ref('')
      const userInputPassword= ref('')
      const showPassword = ref(false)  // 添加这行
      const talkContent = ref(null);
      const showPhoneError = ref(false)
      const showPasswordError = ref(false)
      const isNewPage = ref(true)
      const isErrorToast = ref(false)
      const isNotice = ref(false)
      const errorToast = ref('')
      const noticeToast = ref('')
      const eventSource = ref(null)
      const route = useRoute();
      const agentId = ref('1')
      // const isThinking = ref(isThinkingAgents[agentId])
      // const isTalking = ref(isTalkingAgents[agentId])
      const agent_name = ref('')
      const agent_creator = ref('')
      const agent_desc = ref('')
      const router = useRouter()
      const thisChatHistory = ref([])
      const chat_conversion_list = ref([])
      const conversion_id = ref('')
      const conversion_id_page = ref(1)
      const historyListOpen = ref(false)
      const hasMoreChat = ref(false)
      const nextPageReqIsLoading = ref(false)
      const videoIsPlaying = ref({})
      const isPlaying = ref(false)
      let audioPlayer = null // 用于播放频的元素

      // const voiceInpuing = ref (false)
      // let recognition; // 用于存储 SpeechRecognition 实例

      // 在 setup 函数开始处添加新的响应式变量
      const historyListPage = ref(1)
      const historyHasMore = ref(false)
      const historyLoading = ref(false)

      const closeHistoryList = () =>{
        historyListOpen.value = false
      }

      // 修改 getAgentImage 方法
      const getAgentImage = (agentId) => {
        try {
          // 尝试加载对应的图片
          return require(`../assets/agent_image/agent_id_${agentId}.png`)
        } catch (error) {
          // 如果找不到对应图片，返回默认图片
          return defaultAgentImage
        }
      }



      // const getThisChatHistory = () => {
      //   if (chatHistory.value[agentId.value]){
      //     thisChatHistory.value = chatHistory.value[agentId.value] // 获取查询参数 id
      //     console.log('thisChatHistory.value,222',thisChatHistory.value)
      //   }
      // }

      // 创建 SpeechRecognition 实例
      // const createSpeechRecognition = () => {
      //   const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      //   if (!SpeechRecognition) {
      //     alert("您的浏览器不支持语音识别，请使用支持的浏览器，如 Chrome。");
      //     return null;
      //   }
      //   recognition = new SpeechRecognition();
      //   recognition.lang = 'zh-CN';  // 设置语言为中文
      //   recognition.interimResults = false;  // 禁用中间结果
      //   recognition.maxAlternatives = 1;  // 限制为一种结��
      //   if (recognition) {
      //     recognition.onresult = (event) => {
      //       const transcript = event.results[0][0].transcript;  // 获取识别文本
      //       userMessage.value = transcript;  // 更新文本
      //     };

      //     recognition.onerror = () => {
      //       return recognition
      //     };
      //   }
      //   return recognition;
      // };

      // // 启动语音识别
      // const startVoiceInput = () => {
      //   console.log('start')
      //   voiceInpuing.value = true
      //   if (recognition) {
      //     recognition.start();
      //   }else{
      //     errorSet('语音识别暂不可用,请联系管理���')
      //   }
      // };

      // // 启动语音识别
      // const stopVoiceInput = () => {
      //   console.log('stop')
      //   voiceInpuing.value = false
      //   if (recognition) {
      //     recognition.stop();
      //   }else{
      //     errorSet('语音识别暂不可用,请联系管理员')
      //   }
      // };



      const getAgentId = () =>{
        if (route.query.id){
          agentId.value = String(route.query.id)
        }else{
          agentId.value = '1'
        }
      }

      //添加一个标志来控制是否允许加载更多
      const allowLoadMore = ref(true)

      //创建一个新对话
      const createNewTalk = async () => {
        // 是否登陆
        if (!whetherLogin.value) {
          noticeSet(isNotice, noticeToast, '登陆后才能创建对话,请先登陆')
          return
        }
        if (isTalkingAgents.value[agentId.value]) {
          noticeSet(isNotice, noticeToast, '正在回答问题,请稍后')
          return 
        }
        if (isNewPage.value) {
          noticeSet(isNotice, noticeToast, '当前为新对话')
          return
        }

        // ���时禁用加�������功能
        allowLoadMore.value = false
        
        userMessage.value = ''
        thisChatHistory.value = []
        conversion_id.value = ''
        
        await nextTick()
        whetherisNewPage()
        
        // 短暂延迟后重新启用加载更多功能
        setTimeout(() => {
          allowLoadMore.value = true
        }, 500)
      }

      const whetherisNewPage = () =>{
        if (thisChatHistory.value.length == 0){
          isNewPage.value = true
        }else{
          isNewPage.value = false
        }
      }

    // 自动滚动到最底部
    const scrollToBottom = () => {
      const container = talkContent.value;
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    };

    const handleScroll = async() => {
      if (!allowLoadMore.value) return // 如果不允许加载更多，直接返回
      
      const container = talkContent.value
      if (container.scrollTop === 0 && hasMoreChat.value && !nextPageReqIsLoading.value) {
        await getChatDetail(conversion_id.value, conversion_id_page.value + 1)
      }
    }
  
    const renderMarkdown = (text) => {
      if (text){
        return marked(text)
      }
    };

    const closeLoginPage = () =>{
      loginPage.value = false
    }
    
    //账号验证
    const validatePhoneNumber = (phoneNumber) => {
      const phoneRegex = /^1[3-9]\d{9}$/;
      return phoneRegex.test(phoneNumber);
    }
    const validatePassWord = (password) => {
      const phoneRegex = /[A-Za-z]{8}$/;
      return phoneRegex.test(password);
    }
    const validateMessageInput = (message) => {
      const trimmedInput = message.trim()
      return trimmedInput.length > 0 && trimmedInput.length <= 10000
    }

      const errorSet = (mes) =>{
        isErrorToast.value = true
        errorToast.value = mes
        setTimeout(() => {
            isErrorToast.value = false
        }, 3000);
      }

      // const noticeSet = (isNotice, noticeToast, mes) => {
      //   isNotice.value = true;
      //   noticeToast.value = mes;
      //   setTimeout(() => {
      //     isNotice.value = false;
      //   }, 3000);
      // };

    // 页面加载完成时自动滚动到底部
    onMounted(async() => {
      userStore.rmTalkingAgent(agentId.value)
      userStore.rmThinkingAgent(agentId.value)
      const err = await getUserInfo(whetherLogin,userPhoneNumber)
      if (err){
        noticeSet(isNotice,noticeToast,'登陆过期，清重新登陆')
      }
      await getAgentInfo();
      // await getChatDetail('',1);
      whetherisNewPage(); 
      // await nextTick();
      // createSpeechRecognition(); //语音输入
      // getThisChatHistory();
      // getAgentId();
      // getAgentInfo();
    });
    onBeforeMount(()=>{
      // scrollToBottom();
      // whetherisNewPage();
      getAgentId();
      // getAgentInfo();
      // getThisChatHistory();
    });

    // const checkToken = () => {
    //     const storedData = localStorage.getItem('token');
    //     let token = '';
    //     let expireTime = 0;
    //     if (storedData) {
    //       const { token: storedToken,expire_time} = JSON.parse(storedData); // 解析存储的 JSON
    //       token = storedToken; // 获取 token
    //       expireTime = expire_time
    //     }
    //     if (token == ''){
    //       // noticeSet('请先登')
    //       loginPage.value = true
    //       return { isValid: false, token: '' };
    //     }
    //     // 检查 token 是否过期
    //     if (Date.now()/1000 > expireTime) {
    //       //重新登陆
    //       noticeSet('登陆已失效，请重新登陆')
    //       loginPage.value = true
    //       return { isValid: false, token: '' };
    //     }
    //     return { isValid: true, token: token };
    // }





    // 检查是否正在播放某个 message 的音频
    const getPlayVideoStatus = (contentId) =>{
        const help =  agentId.value + contentId
        return videoIsPlaying.value[help] || false;
    }

    // ���加一个标志来跟踪请求状态
    const isRequesting = ref(false)

    // 修改 optionPlayVideo 函数
    const optionPlayVideo = async(contentId) => {
        const help = agentId.value + contentId
        
        // 如果正在请求中，直接返回
        if (isRequesting.value) {
            return
        }

        // 如果正在播放，处理暂停逻辑
        if (isPlaying.value) {
            try {
                if (audioPlayer) {
                    await audioPlayer.pause()
                    // 重置所有播放状态
                    Object.keys(videoIsPlaying.value).forEach(key => {
                        videoIsPlaying.value[key] = false
                    })
                    isPlaying.value = false
                }
            } catch (error) {
                console.error('暂停音频失败:', error)
            }
            return
        }

        try {
            isRequesting.value = true  // 设置请求标志

            const {isValid, token} = checkToken()
            if (!isValid) {
                loginPage.value = true
                return
            }

            // 在设置播放状态之前，确保之前的音频已经清理
            if (audioPlayer) {
                try {
                    audioPlayer.pause()
                    audioPlayer.onended = null
                    audioPlayer.onerror = null
                    audioPlayer = null
                } catch (error) {
                    console.error('清理旧音频播放器失败:', error)
                }
            }

            // 设置播放状态
            videoIsPlaying.value[help] = true
            isPlaying.value = true

            const url = new URL('https://www.henangaokao.love/kun-peng-ai/play-talk-video')
            url.search = new URLSearchParams({
                'agent_id': agentId.value,
                'chat_id': conversion_id.value,
                'content_id': contentId
            })

            const response = await fetch(url, {
                method: 'get',
                headers: {
                    'token': token,
                    'Content-Type': 'application/json',
                },
            })

            if (!response.ok) {
                throw new Error('Network response was not ok')
            }

            const audioData = await response.blob()
            const audioBlob = new Blob([audioData], { type: 'audio/mp3' })
            const audioUrl = URL.createObjectURL(audioBlob)

            // 创建新的音频播放器
            audioPlayer = new Audio()
            
            // 设置音频结束时的回调
            audioPlayer.onended = () => {
                videoIsPlaying.value[help] = false
                isPlaying.value = false
                URL.revokeObjectURL(audioUrl)
            }

            // 设置错误处理
            audioPlayer.onerror = (e) => {
                console.error('音频播放错误:', e)
                errorSet('音频播放出错,请重试')
                videoIsPlaying.value[help] = false
                isPlaying.value = false
                URL.revokeObjectURL(audioUrl)
            }

            audioPlayer.src = audioUrl

            // 使用 try-catch 包装 play() 调用
            try {
                await audioPlayer.play()
            } catch (playError) {
                console.error('播放错误:', playError)
                throw playError  // 重新抛出错误以便外层捕获
            }

        } catch (error) {
            console.error('音频处理错误:', error)
            errorSet('播放失败,请重试或联系管理员')
            // 重置所有状态
            videoIsPlaying.value[help] = false
            isPlaying.value = false
            if (audioPlayer) {
                try {
                    audioPlayer.pause()
                    audioPlayer.src = ''
                    audioPlayer = null
                } catch (cleanupError) {
                    console.error('清理音频播放器失败:', cleanupError)
                }
            }
        } finally {
            isRequesting.value = false  // 重置请求标志
        }
    }
    

  

      // �� setup 中添加相关变量和方法
      const deleteConfirmVisible = ref(false);
      const pendingDeleteId = ref('');

      // 修改删除方法
      const deleteChatHistory = ($event, deleteConversionId) => {
        event.stopPropagation();
        pendingDeleteId.value = deleteConversionId;
        deleteConfirmVisible.value = true;
      };

      // 取消删除
      const cancelDelete = () => {
        deleteConfirmVisible.value = false;
        pendingDeleteId.value = '';
      };

      // 确认删除
      const confirmDelete = async () => {
        const {isValid, token} = checkToken();
        if (!isValid) {
          noticeSet(isNotice, noticeToast, '删除失败,请先登陆');
          loginPage.value = true;
          return;
        }

        if (pendingDeleteId.value === '') {
          errorSet('对话记录不存在，请重试');
          return;
        }

        const reqData = {
          'agent_id': agentId.value,
          'conversation_id': pendingDeleteId.value
        };

        try {
          const response = await axios.post('https://www.henangaokao.love/kun-peng-ai/delete-chat-history', reqData, {
            headers: {
              'token': token,
              'Content-Type': 'application/json'
            }
          });
          const data = response.data;
          if (data.code == 0) {
            noticeSet(isNotice, noticeToast, '删除成功');
            window.location.reload();
          } else {
            errorSet('删除失败，请重试');
          }
        } catch(error) {
          errorSet('删除失败,请稍后重试或联系管理员');
        } finally {
          deleteConfirmVisible.value = false;
          pendingDeleteId.value = '';
        }
      };

      // const getUserInfo = async() =>{
      //   const {isValid ,token} =  checkToken()
      //   if (!isValid){
      //       loginPage.value =true
      //     return 
      //   }
      //   try {
      //     const response = await axios.get('https://www.henangaokao.love/kun-peng-ai/user-info',{
      //       headers:{
      //         'token':token
      //       }
      //     })
      //     const data = response.data.data
      //     const code = response.data.code
      //     console.log('data',data)
      //     if (code !=0 ) {
      //       errorSet('用户信息错误,请重新登')
      //     }
      //     whetherLogin.value = true
      //     userPhoneNumber.value = data.phone_number
      //   }catch(error){
      //     errorSet('获取用户详情失败,请联系管理员')
      //   }
      // }

      const getChatDetail = async(conversationId,page) => {
        nextPageReqIsLoading.value = true
        const {isValid ,token} =  checkToken()
        if (!isValid){
          return
        }
        conversion_id_page.value = page
        try {
          const response = await axios.get('https://www.henangaokao.love/kun-peng-ai/chat-history-detail',{
            params:{
              "agent_id":agentId.value,
              "conversation_id":conversationId,
              'page':page
            },
            headers:{
              "token":token
            }
          })
          const data = response.data.data
          if (data.chat_histories){
              if (page == 1){
                thisChatHistory.value = data.chat_histories
                await nextTick()
                setTimeout(scrollToBottom, 50);  // 延迟 50ms 调用滚动到底部
              }else{
                thisChatHistory.value.unshift(...data.chat_histories); // 将聊天记录插入到开头
                await nextTick()
                if (talkContent.value){
                  let totalHeight = 0;
                  // 遍历前20条消息
                  const limit = Math.min(20, data.chat_histories.length);  // 防止数据量少于20条
                  let aiNum = 0
                  let userNum = 0
                  for (let i = 0; i < limit; i++) {
                    let messageElement = null
                    if (data.chat_histories[i].role == 'user'){
                      const messageElements = talkContent.value.querySelectorAll('.ai'); // 假设每个消息都有 .message 类
                      messageElement = messageElements[userNum]
                      userNum++
                    }else{
                      const messageElements = talkContent.value.querySelectorAll('.user'); // 假设每个消息都有 .message 类
                      messageElement = messageElements[aiNum]
                      aiNum++
                    }
                    if (messageElement) {
                      totalHeight += messageElement.offsetHeight
                    }
                  }
                  talkContent.value.scrollTop = totalHeight;
                }
              }
          }
          conversion_id.value = data.conversation_id
          hasMoreChat.value = data.has_more
        }catch(error){
          errorSet('获取详情失败,请联系管理员')
        }finally{
          whetherisNewPage()
          historyListOpen.value = false
          nextPageReqIsLoading.value = false
        }
      }

    //��取智能体历史聊天记录
    const getChatHistoryList = async(page = 1) => {
      historyListOpen.value = true
      // 如果是第一页，清空现有列表
      if (page === 1) {
        chat_conversion_list.value = []
      }
      
      // 如果正在加载或没有更多数据，直接返回
      if (historyLoading.value || (page > 1 && !historyHasMore.value)) {
        return
      }

      const {isValid, token} = checkToken()
      if (!isValid) {
        return
      }

      try {
        historyLoading.value = true
        const response = await axios.get('https://www.henangaokao.love/kun-peng-ai/all-chat-list', {
          params: {
            "agent_id": agentId.value,
            "page": page
          },
          headers: {
            "token": token
          }
        })
        const data = response.data.data
        if (data.chat_conversion_list) {
          // 追加新数据而不是替换
          chat_conversion_list.value = [...chat_conversion_list.value, ...data.chat_conversion_list]
          if (chat_conversion_list.value[0]) {
            conversion_id.value = chat_conversion_list.value[0].conversion_id
          }
          // 更新分页相关状态
          historyHasMore.value = data.has_more
          historyListPage.value = page
        }
      } catch(error) {
        errorSet('获取历史消息列表失败')
      } finally {
        historyLoading.value = false
      }
    }

    // 添加历史列表滚动处理函数
    const handleHistoryScroll = async(event) => {
      const element = event.target
      // 检查是否滚动到底部
      if (element.scrollHeight - element.scrollTop <= element.clientHeight + 50) { // 预加载阈值为50px
        if (historyHasMore.value && !historyLoading.value) {
          await getChatHistoryList(historyListPage.value + 1)
        }
      }
    }

    // const getChatHistoryDetail = async() => {

    // }

      //确认登陆
      const loginSure = async() => {
        showPhoneError.value = !validatePhoneNumber(userInputId.value)
        showPasswordError.value = !validatePassWord(userInputPassword.value)
        if (!showPhoneError.value && !showPasswordError.value) {
          //发送登陆请求
          try {
            const response = await axios.post('https://www.henangaokao.love/kun-peng-ai/login', {
              phone_number: userInputId.value,
              pass_word: userInputPassword.value
            });
            const data = response.data
            if (data.code == 0) {
              //保存token和过期时间到本地
              userStore.saveTokenAndExpire(data.data.token, data.data.expire_time)
              loginPage.value = false
              
              // 先显示提示
              noticeSet(isNotice, noticeToast, '登录成功')
              
              // 延迟执行路由跳转和页面刷新
              setTimeout(() => {
                router.push('/').then(() => {
                  window.location.reload();
                });
              }, 1000); // 延迟1秒，确保提示能够显示
            } else {
              errorSet(data.msg)
            }
          } catch(error) {
            errorSet('登录请求失败')
          }
        } else {
          errorSet('登录失败,请重试')
        }
      };

      const signOutSure = () => {
        outPage.value = false
        localStorage.clear()
        // 延迟执行路由跳转和页面刷新
        noticeSet(isNotice,noticeToast,'退出成功')
        setTimeout(() => {
          router.push('/').then(() => {
            window.location.reload();
          });
        }, 1000); // 延迟1秒，确保提示能够显示
      };

      const wheatherLoginTest = (msg) =>{
          //是否登陆
          if (!whetherLogin.value){
            noticeSet(isNotice,noticeToast,msg)
            return 
        }
      };

      //获取智能体信息
      const getAgentInfo = async() =>{
        const {isValid ,token} =  checkToken()
        if (!isValid){
          return
        }
        try {
          const response = await axios.get('https://www.henangaokao.love/kun-peng-ai/agent-info',{
            params:{
              "agent_id":agentId.value,
            },
            headers:{
              "token":token
            }
          })
          const data = response.data.data
          if (data) {
            agent_name.value = data.agent_name
            agent_creator.value = data.agent_creator
            agent_desc.value = data.agent_desc
          }
        }catch(error){
          errorSet('获取智能体信息失败,请重试')
        }
      };

      const sendMessage = async() => {
        const {isValid ,token} =  checkToken()
        if (!isValid){
          noticeSet(isNotice,noticeToast ,'登陆后才能发送消息')
          return
        }

        //是否登陆
        if (!whetherLogin.value){
          noticeSet(isNotice,noticeToast ,'登陆后才能发送消息')
          return
        }

        //消息不能是��空格，字数限制
        if (!validateMessageInput(userMessage.value)){
          errorSet('消息不能是空,请输入')
          userMessage.value = ''
          return
        }

        if (isTalkingAgents.value[agentId.value] === true || isThinkingAgents.value[agentId.value] === true){
          return 
        }

        userStore.addTalkingAgent(agentId.value)
        userStore.addThinkingAgent(agentId.value)
        thisChatHistory.value.push({role:'user',content:userMessage.value})
        whetherisNewPage()
        let userText = userMessage.value
        userMessage.value = ''
        await nextTick()
        scrollToBottom()
        //调接口
        try  {const response =  await fetch('https://www.henangaokao.love/kun-peng-ai/talk',{
                method:'post',
                headers:{
                    'Content-Type': 'application/json',
                    'token':token
                },
                body:JSON.stringify({question:userText , chat_id:conversion_id.value ,agent_id:agentId.value})
            })
        // 检查响应状态
        if (!response.ok) {
          console.error('Network response was not ok:', response.statusText);
          errorSet('流式输出发生错误，请重试');
          return;
        }
        let aiText = '' //ai返回数据
        let aiTextId = 0
        // 处理应流
        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');
        let done = false;
        let isFirst = true
        while (!done) {
          const { value, done: readerDone } = await reader.read();
          done = readerDone;
          // 将流式输出的数据转为字符串
          const chunk = decoder.decode(value, { stream: true });
          const dataChunks = chunk.split('next-struct'); // 根据实际分隔符分割，比如换行符
          for (let dataChunk of dataChunks) {
            if (dataChunk.trim() !== "") {  // 确保数据不为空
              //结束
              if (dataChunk.includes('"code":' && '"msg":' && '"data":')){
                const dataChunkJson = JSON.parse(dataChunk);
                if (dataChunkJson.data){
                  conversion_id.value = dataChunkJson.data.chat_id
                  // 找到正在生成的消息并标记为完成
                  const index = thisChatHistory.value.findIndex(item => item.content_id === aiTextId)
                  if (index !== -1) {
                    thisChatHistory.value[index].isGenerating = false  // 添加这行，设置消息生成完成
                  }
                }
                if (dataChunkJson.code !== 0){
                  errorSet(dataChunkJson.msg)
                }
                break
              }

              const parsedData = JSON.parse(dataChunk)
              // 解析服务端返回的流式数据 (需要根据你的服务端数据格式处理)
              if (parsedData.text != "" &&parsedData.id !=0) {
                userStore.rmThinkingAgent(agentId.value)
                aiText += parsedData.text;
                // 存储 AI 回复
                if (isFirst) {
                  aiTextId = parsedData.id
                  thisChatHistory.value.push({
                    role: 'ai',
                    content: aiText,
                    content_id: aiTextId,
                    isGenerating: true  // 添加生成状态标记
                  })
                  isFirst = false
                }else{
                  const index = thisChatHistory.value.findIndex(item => item.content_id === aiTextId)
                  if (index !== -1){
                    thisChatHistory.value[index].content =  aiText
                  }
                }
                scrollToBottom()
              }
            }
          }
        }
        scrollToBottom()
      }catch(error){
        errorSet('服务停升级，请过段时间再试')
      }finally{
        scrollToBottom()
        userStore.rmThinkingAgent(agentId.value)
        userStore.rmTalkingAgent(agentId.value)
      }
      };

      onBeforeUnmount(()=>{
        if (eventSource.value){
          eventSource.value.close()
        }
        if (audioPlayer) {
            audioPlayer.pause()
            audioPlayer.src = ''
            audioPlayer = null
        }
        // 重置所有状态
        Object.keys(videoIsPlaying.value).forEach(key => {
            videoIsPlaying.value[key] = false
        })
        isPlaying.value = false
      })
      const login= () => {
        loginPage.value = true
      };

      const signOut = () => {
        outPage.value = true
      };

      const optionMenu = () => {
        openMenu.value = !openMenu.value;
      };

      const cancle = () => {
        outPage.value = false
      };

      const navigateTo = (page,id) =>{
        if (page === 'talk') {
          if (id !== '') {
            router.push({path:'talk' , query :{id}}).then(() => {
              location.reload()
            }); //跳转到智能体对话
          }else{
            router.push({path:'talk'}).then(() => {
              location.reload()
            }) ; //跳转到鲲鹏ai对话助手
          }
          openMenu.value = false
        } else if (page === 'my-ai') {
          router.push('/my-ai').then(()=>{
            location.reload()
          }); // 跳转到我的智能体页面
          // openMenu.value = false
        }else if (page === 'index'){
          router.push('/index').then(()=>{
            location.reload()
          }); // 转到鲲鹏2.0对话助手页面
          // openMenu.value = false
        }
      }

      const copyText = async (text) => {
        try {
          await navigator.clipboard.writeText(text)
          noticeSet(isNotice, noticeToast, '复制成功')
        } catch (err) {
          errorSet('复制失败，请重试')
        }
      }

      // 在 setup 函数中添加
      const isActiveRoute = (path, id = '') => {
        if (path === 'talk') {
          if (id === '') {
            // 对于第一个 tab，需要判断是否在 talk 页面且没有 id 参数
            return route.path === '/talk' && !route.query.id;
          }
          return route.path === '/talk' && route.query.id === id;
        }
        return route.path === `/${path}`;
      };

      const togglePasswordVisibility = () => {  // 添加这个方法
        showPassword.value = !showPassword.value
      }

      return {historyListOpen,agent_name,agent_creator,agent_desc,agentId,isThinkingAgents,isTalkingAgents ,isErrorToast,isNotice,errorToast,noticeToast,
        isNewPage,loginPage,outPage,userMessage, openMenu,whetherLogin , userPhoneNumber  ,thisChatHistory,userInputId,
        
        userInputPassword,talkContent,chat_conversion_list,renderMarkdown,login,signOut ,optionMenu,cancle,loginSure, signOutSure,sendMessage,createNewTalk,
        closeLoginPage,wheatherLoginTest,navigateTo,getChatHistoryList,closeHistoryList,getChatDetail,deleteChatHistory,handleScroll,
        optionPlayVideo,getPlayVideoStatus,copyText,getAgentImage,isActiveRoute, deleteConfirmVisible, pendingDeleteId, confirmDelete, cancelDelete,
        historyListPage, historyHasMore, historyLoading, handleHistoryScroll,
        showPassword,
        togglePasswordVisibility
      }
    },


  }
  </script>
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .index{
    width: 100%;
    max-width: 500px;
    height: 100vh;
    margin: 0 auto;
    position: relative;
    overflow-x: hidden;
  }

  .phone {
      width: 100%;
      height: 100%;
      margin: auto;
      display: flex;
      flex-direction: column;
      background-color: rgba(247, 246, 246, 1.00);
      position: relative;
    }

  .head{
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    background: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    padding: 0 16px;
    position: relative;
    flex-shrink: 0;
    transition: all 0.3s ease;
  }
  .menu-button{
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    transition: all 0.2s ease;
    margin-right: 8px;
    cursor: pointer;  /* 添加鼠标小手 */
  }
  .menu-button:hover{
    background-color: rgba(24, 160, 251, 0.08);
    transform: scale(1.05);
  }

  .menu{
    width: 24px;
    height: 24px;
    opacity: 0.7;
  }
  .menu:hover{
    cursor: pointer;
  }

  .menu-list{
    position: absolute;
    top: 0;
    left: 0;
    width: 280px;
    height: 100vh;
    background-color: white;
    z-index: 100;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    transform-origin: left center;
    will-change: transform, opacity;
    overflow-x: hidden;
    background: linear-gradient(to bottom, #ffffff, #f8f9fa);
    border-right: 1px solid rgba(0, 0, 0, 0.08);
  }

  .tabs{
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;  
    flex-grow: 1;
  }

  .tab{
    display: flex;
    width: 100%;
    height: 60px;
    padding: 16px 24px;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    transition: all 0.2s ease;
    cursor: pointer;  /* 添加鼠标小手 */
    position: relative;
    overflow: hidden;
    background: linear-gradient(to right, rgba(24, 160, 251, 0), transparent);
  }

  .tab:hover{
    cursor: pointer;
    background: linear-gradient(to right, rgba(24, 160, 251, 0.05), transparent);
  }

  .tab::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    height: 0;
    background: #18a0fb;
    transition: height 0.3s ease;
  }

  .tab:hover::before {
    height: 70%;
  }

  .tab-icon{
    width: 24px;
    height: 24px;
    margin-right: 12px;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    padding: 8px;
    border-radius: 12px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
  }

  .tab-icon img {
    width: 25px;
    height: 25px;
    object-fit: contain; /* 保持图片比例 */
    border-radius: 25px;

  }

  .tab-name{
    font-size: 16px;
    color: #333;
    font-weight: 500;
    transition: all 0.3s ease;
    position: relative;
  }

  .tab:hover .tab-name p {
    color: #18a0fb;
    transform: translateX(4px);
  }

  .my-center{
    height: 80px;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    display: flex;
    align-items: center;
    padding: 16px 24px;
    background: linear-gradient(to right, #f8f9fa, #ffffff);
    border-top: 1px solid rgba(24, 160, 251, 0.1);
    transition: all 0.3s ease;
  }

  .my-center:hover {
    background: linear-gradient(to right, #f0f7ff, #ffffff);
  }

  .my-image{
    width: 48px;
    height: 48px;
    border-radius: 24px;
    border: 2px solid white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    margin-right: 5px;
  }

  .my-center:hover .my-image {
    transform: scale(1.05);
    box-shadow: 0 4px 12px rgba(24, 160, 251, 0.2);
  }

  .phone-number{
    font-size: 14px;
    color: #666;
    flex-grow: 1;
    margin-right: 2px;
    font-weight: 500;
    color: #444;
    transition: all 0.3s ease;
  }

  .my-center:hover .phone-number {
    transform: translateX(4px);
  }

  .login{
    padding: 6px 12px;
    border-radius: 6px;
    background: linear-gradient(135deg, #18a0fb, #0d8ee9);
    transition: all 0.2s ease;
    box-shadow: 0 2px 6px rgba(24, 160, 251, 0.2);
    color: white;
  }

  .login:hover{
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(24, 160, 251, 0.3);
  }

  .login p{
    color: white;
    font-size: 14px;
    margin: 0;
    cursor: pointer;  /* 添加鼠标小手 */
  }

  .close-menu{
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    margin: 20px 0 0 20px;
    transition: all 0.2s ease;
  }
  .close-menu:hover{
    background-color: rgba(24, 160, 251, 0.08);
    transform: scale(1.05);
  }
  .close-menu-img{
    width: 24px;
    height: 24px;
    opacity: 0.7;
  }

  .logo{
    width: 150px;
    height: 150px;
    margin-top: 30px;
    margin: auto;
    margin-bottom:16px ;
    flex-shrink: 0;
  }

  .logo-2{
    display: flex;
    align-items: center;
    padding: 8px 16px;
    border-radius: 12px;
    background: rgba(24, 160, 251, 0.05);
    transition: all 0.2s ease;
    margin-left: 4px;
  }
  .logo-2:hover{
    background: rgba(24, 160, 251, 0.08);
    transform: translateY(-1px);
  }
  .logo-2-image{
    width: 32px;
    height: 32px;
    border-radius: 8px;
  }
  .logo-2-name{
    margin-left: 12px;
    font-size: 16px;
    font-weight: 500;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
  }

  .talk-icon{
    display: flex;
    gap: 12px;
    margin-left: auto;
  }

  .new-talk{
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    transition: all 0.2s ease;
    position: relative;
    cursor: pointer;  /* 添加鼠标小手 */
  }
  .get-history-list{
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    transition: all 0.2s ease;
    position: relative;
    cursor: pointer;  /* 添加鼠标小手 */
  }
  .new-talk:hover{
    background-color: rgba(24, 160, 251, 0.08);
    transform: scale(1.05);
  }
  .get-history-list:hover{
    background-color: rgba(24, 160, 251, 0.08);
    transform: scale(1.05);
  }
  .new-talk::after,
  .get-history-list::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    padding: 4px 8px;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    font-size: 12px;
    border-radius: 4px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease;
  }
  .new-talk:hover::after,
  .get-history-list:hover::after {
    opacity: 1;
    visibility: visible;
  }
  .new-talk-icon {
    width: 24px;
    height: 24px;
    opacity: 0.8;
    transition: all 0.2s ease;
  }
  .new-talk:active,
  .get-history-list:active {
    transform: scale(0.95);
  }
  .history-list{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #f8f9fa;
  }
  .history-list-head{
    display: flex;
    align-items: center;
    height: 70px;
    background: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    padding: 0 16px;
    position: relative;
    flex-shrink: 0;
  }
  .back-icon{
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    transition: all 0.2s ease;
    cursor: pointer;  /* 添加鼠标小手 */
  }
  .back-icon:hover{
    background-color: rgba(24, 160, 251, 0.08);
    transform: scale(1.05);
  }
  .back-icon-image{
    width: 24px;
    height: 24px;
    opacity: 0.7;
  }
  .history-list-title{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .history-list-title-p{
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin: 0;
  }
  .history-list-head-right{
    width: 50px;
  }
  .history-list-body{
    flex-grow: 1;
    overflow-y: auto;
    padding: 16px;
  }
  .history-info{
    background: white;
    border-radius: 12px;
    margin-bottom: 12px;
    padding: 16px;
    display: flex;
    align-items: flex-start;
    transition: all 0.2s ease;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    border: none;
    width: 100%;
    cursor: pointer;  /* 添加鼠标小手 */
  }
  .history-info:hover{
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    background-color: rgba(24, 160, 251, 0.02);
  }
  .history-info-word{
    flex: 1;
    min-width: 0;
    padding-right: 16px;
  }
  .history-desc{
    font-size: 15px;
    font-weight: 500;
    color: #333;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    text-align: left;
  }
  .history-time{
    font-size: 12px;
    color: #888;
    margin: 6px 0 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;

  }
  .history-delete{
    flex-shrink: 0;
    width: 42px;
  }
  .history-delete-div{
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    transition: all 0.2s ease;
    cursor: pointer;  /* 添加鼠标小手 */
  }
  .history-delete-div:hover{
    background-color: rgba(255, 59, 48, 0.1);
  }
  .history-delete-div img{
    width: 20px;
    height: 20px;
    opacity: 0.7;
    transition: all 0.2s ease;
  }
  .history-delete-div:hover img{
    opacity: 1;
    transform: scale(1.1);
  }
  .history-list-body::-webkit-scrollbar{
    width: 6px;
  }
  .history-list-body::-webkit-scrollbar-track{
    background: transparent;
  }
  .history-list-body::-webkit-scrollbar-thumb{
    background: rgba(0, 0, 0, 0.1);
    border-radius: 3px;
  }
  .empty-history{
    text-align: center;
    padding: 40px 20px;
    color: #666;
  }
  .empty-history p{
    font-size: 16px;
    margin: 0;
  }
  .new-talk:hover{
    cursor: pointer;
    background-color:rgba(196, 196, 196, 0.40) ;
    border-radius: 10px;
  }
  .new-talk-icon{
    width: 24px;
    height: 24px;
    opacity: 0.7;
  }
  .title{
    width: 300px;
    height: 50px;
    max-width: 100%;
    margin: auto;
    flex-shrink: 0;
  }
  .desc{
    width: 350px;
    max-width: 90%;
    margin: auto;
    margin-top: 15px;
  }
  .agent_desc{
    font-size: 14px;
    text-align: left;
  }
  .name{
    font-size: 18px;
    margin-bottom: 2px;
    font-weight: 500;
  }
  .creater{
    font-size: 12px;
    font-weight: 300;
  }
  .talk-content {
    flex-grow: 1; /* 占���剩余高度 */
    overflow-y: auto; /* 如果内容超出容器，添加滚动条 */
    display: flex;
    flex-direction: column;
    padding: 20px;
    /* white-space: pre-wrap; */
    margin-top: 20px;
  }
  .ai{
    width: 90%;
    max-width: 400px;
    /* 使用渐变景增加视觉层次感 */
    background: linear-gradient(to right, #f8f9fa, #ffffff);
    margin: 12px 0;
    align-self: flex-start;
    border-radius: 15px 15px 15px 2px;
    font-size: 15px;
    /* 添加阴影提升立体感 */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
    /* 添加左侧强调边框 */
    border-left: 4px solid #18a0fb;
    /* 添加过渡效果 */
    transition: transform 0.2s ease;
  }
  .ai:hover {
    /* 悬停时轻微上浮效果 */
    transform: translateY(-2px);
  }
  .user{
    width: 90%;
    max-width: 400px;
    /* 使用渐变背景使颜色更丰富 */
    background: linear-gradient(135deg, #18a0fb, #0d8ee9);
    margin: 12px 0;
    align-self: flex-end;
    border-radius: 15px 15px 2px 15px;
    font-size: 15px;
    /* 添加阴影效果 */
    box-shadow: 0 2px 6px rgba(24, 160, 251, 0.2);
    /* 添加过渡效果 */
    transition: transform 0.2s ease;
  }
  .user:hover {
    /* 悬停时轻微上浮效果 */
    transform: translateY(-2px);
  }
  .message {
    text-align: left;
    margin: 12px 16px;
    overflow-wrap: break-word;
    max-width: 100%;
    line-height: 1.5;
    color: #333333;
  }
  .user .message {
    color: #ffffff;
  }
  .message pre {
    background: #2d2d2d;
    border-radius: 8px;
    padding: 16px;
    margin: 8px 0;
    overflow-x: auto;
    font-family: 'Fira Code', monospace;
    font-size: 14px;
  }
  .message-bottom-select {
    height: 40px;
    display: flex;
    margin: 0 16px;
    align-items: center;
    gap: 16px; /* 添加按钮���间的间距 */
    border-top: 1px solid rgba(0, 0, 0, 0.08);
  }
  .play-video {
    height: 28px;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 6px;
    padding: 0 12px;
    color: #666;
    transition: all 0.2s ease;
    cursor: pointer;  /* 添加鼠标小手 */
  }
  .play-video:hover {
    background-color: rgba(24, 160, 251, 0.1);
    color: #18a0fb;
  }
  .play-video-icon {
    margin-right: 6px;
    width: 18px;
    height: 18px;
    opacity: 0.8;
  }
  .talk-content {
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 24px;
    background: linear-gradient(to bottom, #f8f9fa 0%, #ffffff 100%);
  }
  .talk-content::-webkit-scrollbar {
    width: 6px;
  }
  .talk-content::-webkit-scrollbar-track {
    background: transparent;
  }
  .talk-content::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 3px;
  }
  .ai .dot {
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #666;
    margin: 0 2px;
    animation: blink 1s infinite steps(1, end);
  }
  @keyframes blink {
    0%, 100% { opacity: 0; }
    50% { opacity: 1; }
  }
  .bottom{
    padding: 16px 24px;
    background: white;
    box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.05);
    height: 100px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .message{
    text-align: left;
    margin: 6px;
    overflow-wrap: break-word;
    max-width: 100%;
  }
  .message-bottom-select{
    height: 35px;
    display: flex;
    margin: auto;
    margin-left: 6px;
    align-items: center;
    border-top: 1px solid #ddd;
    cursor: pointer;
  }
  .play-video{
    height: 25px;
    font-size: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .play-video:hover{
    background-color:rgba(196, 196, 196, 0.40) ;
  }
  .play-video-icon{
    margin-right: 3px;
    width: 20px;
    height: 20px;
  }
  :deep(ul)  ,:deep(ol){
    padding-left: 20px ; /* 设置左侧内边距 */
  }
  .send-content{
    display: flex;
    align-items: center;
    background: white;
    border-radius: 12px;
    border: 2px solid rgba(24, 160, 251, 0.1);
    transition: all 0.2s ease;
    padding: 4px;
    height: 50px;
  }
  .send-content:focus-within {
    border-color: rgba(24, 160, 251, 0.3);
    box-shadow: 0 2px 8px rgba(24, 160, 251, 0.1);
  }
  .input-text{
    flex-grow: 1;
    height: 100%;
    border: none;
    background: transparent;
    padding: 0 16px;
    font-size: 15px;
    color: #333;
  }
  .input-text:focus {
    outline: none;
  }
  .input-text::placeholder {
    color: #999;
  }
  .input-text:disabled {
    background-color: rgba(0, 0, 0, 0.02);
    cursor: not-allowed;
  }
  .send-button, .can-send-button {
    width: 42px;
    height: 42px;
    border: none;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease;
  }
  .send-button {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .can-send-button {
    background-color: #18a0fb;
  }
  .can-send-button:hover {
    background-color: #0d8ee9;
    transform: scale(1.05);
  }
  .send-icon {
    width: 24px;
    height: 24px;
    opacity: 0.8;
  }
  .notice {
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .notice p {
    font-size: 12px;
    color: #999;
    margin: 0;
  }
  .overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(3px);
    z-index: 50;
    transition: all 0.3s ease;
  }
  .overlay2{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 半透明灰色背景 */
    z-index: 110;
  }
  /* 退出登陆页面 */
  .out-page{
    width: 320px;
    height: 120px;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50% , -50%);
    font-size: 20px;
    color: black;
    background-color: white;
    border-radius: 10px;
    z-index: 120;
  }
  .out-page-title{
    height:30px;
    margin: auto;
    margin-top: 10px;
  }
  .out-page-buttons{
    height: 40px;
    width: 280px;
    display: flex;
    justify-content: space-between;
    margin: auto;
    margin-top: 30px;
  }
  .cancle, .yes {
    width: 110px;
    height: 35px;
    border: 1px solid white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    cursor: pointer;
  }

  .cancle {
    background-color: rgba(196, 196, 196, 0.40);
  }

  .cancle:hover {
    background-color: rgba(196, 196, 196, 0.60);
    transform: translateY(-2px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  .cancle:active {
    transform: translateY(0);
    box-shadow: none;
  }

  .yes {
    background-color: #ff4d4f;
    color: white;
  }

  .yes:hover {
    background-color: #ff7875;
    transform: translateY(-2px);
    box-shadow: 0 2px 8px rgba(255, 77, 79, 0.2);
  }

  .yes:active {
    transform: translateY(0);
    box-shadow: none;
  }

  /* 添加按钮文字样式 */
  .cancle p, .yes p {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.2s ease;
  }

  .yes p {
    color: white;
  }
  .login-page{
    width: 320px;
    height: 385px;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50% , -50%);
    font-size: 20px;
    color: black;
    background-color: white;
    border-radius: 10px;
    z-index: 120;
  }
  .login-page-head{
    height:30px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 35px;
  }
  .close{
    position: absolute;
    top: 28px;
    right: 20px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;  /* 添加鼠标小手 */
  }
  .close:hover{
    background-color:rgba(196, 196, 196, 0.40) ;
    border-radius: 10px;
    cursor: pointer;
  }
  .close-icon{
    width: 25px;
    height: 25px;
  }
  .login-page-user-id{
    width: 260px;
    height: 60px;
    margin: auto;
    margin-bottom: 20px;
  }
  .login-page-user-password{
    width: 260px;
    height: 60px;
    margin: auto;
    margin-bottom: 35px;
  }
  .user-id{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: none;
    background-color: rgba(196, 196, 196, 0.30);
    font-size: 16px;
    padding-left: 10px;
  }
  .user-password{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: none;
    background-color: rgba(196, 196, 196, 0.30);
    font-size: 16px;
    padding-left: 10px;
  }
  .login-page-login{
    width: 260px;
    height: 60px;
    border: none;
    border-radius: 10px;
    background-color: rgba(24, 160, 251, 1.00);
  }
  .login-page-login:hover{
    cursor: pointer;
  }
  .error-toast{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 50px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    z-index: 200;
    left: 50%;
    top: 20px;
    transform: translateX(-50%);
    padding: 0 16px;
    pointer-events: none;
  }
  .wrong{
    margin-right: 12px;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
  }
  .wrong-img{
    width: 100%;
    height: 100%;
  }
  .worong-msg{
    font-size: 14px;
    color: #333;
    margin: 0;
  }
  .fade-enter-active {
    animation: slideDown 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
  .fade-leave-active {
    animation: slideUp 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
  @keyframes slideDown {
    0% {
      transform: translate(-50%, -150%);
      opacity: 0;
    }
    100% {
      transform: translate(-50%, 0);
      opacity: 1;
    }
  }
  @keyframes slideUp {
    0% {
      transform: translate(-50%, 0);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, -150%);
      opacity: 0;
    }
  }
  /* Slide transition classes */
  .slide-enter-active, .slide-leave-active {
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
  .slide-enter-from, .slide-leave-to {
    transform: translateX(-100%);
    opacity: 0;
  }
  .slide-enter-to, .slide-leave-from {
    transform: translateX(0);
    opacity: 1;
  }
  .user-input-speack{
    width: 50px;
    height: 50px;
    background-color: red;
  }
  .user-input-speack-stop{
    width: 50px;
    height: 50px;
    background-color: green;
  }
  .input-text:disabled {
    cursor: not-allowed;
  }
  .send-button:disabled,
  .can-send-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  /* 修改新页面相关样式 */
  .logo {
    width: 120px;
    height: 120px;
    margin: 40px auto 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease;
  }
  .logo:hover {
    transform: scale(1.02);
  }
  .logo img {
    width: 80%;
    height: 80%;
    object-fit: contain;
    border-radius: 20px;
  }
  .title {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 0 20px;
    text-align: center;
  }
  .name {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    margin-bottom: 8px;
  }
  .creater {
    font-size: 14px;
    color: #666;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
  .desc {
    width: 100%;
    max-width: 400px;
    margin: 24px auto;
    padding: 20px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  }
  .agent_desc {
    font-size: 15px;
    line-height: 1.6;
    color: #555;
    margin: 0;
    text-align: left;
  }
  /* 调整内容区域背景 */
  .talk-content {
    background: linear-gradient(to bottom, #f8f9fa 0%, #ffffff 100%);
    padding: 20px;
    margin-top: 0;
  }
  /* 新增欢迎提示样式 */
  .welcome-tip {
    text-align: center;
    color: #666;
    font-size: 14px;
    margin: 20px 0;
    opacity: 0.8;
  }
  /* 优化整体布局 */
  .phone {
    background-color: #f8f9fa;
  }
  .copy-text {
    height: 28px;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 6px;
    padding: 0 12px;
    color: #666;
    transition: all 0.2s ease;
    cursor: pointer;  /* 添加鼠标��手 */
  }
  .copy-text:hover {
    background-color: rgba(24, 160, 251, 0.1);
    color: #18a0fb;
  }
  .copy-icon {
    margin-right: 6px;
    width: 18px;
    height: 18px;
    opacity: 0.8;
  }
  /* 添加相同的 CSS 样式 */
  .tab-active {
    background: linear-gradient(to right, rgba(24, 160, 251, 0.1), transparent);
  }

  .tab-active::before {
    height: 70% !important;
  }

  .tab-active .tab-icon {
    transform: translateX(4px);
    box-shadow: 0 4px 12px rgba(24, 160, 251, 0.15);
  }

  .tab-active .tab-name p {
    color: #18a0fb;
    transform: translateX(4px);
  }

  /* 在 style 标签内添加 */
  /* 添加删除确认弹窗样式 */
  .delete-confirm {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 320px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    z-index: 210; /* 确保在最上层 */
    padding: 24px;
  }

  .delete-confirm-title {
    text-align: center;
    margin-bottom: 24px;
  }

  .delete-confirm-title p {
    font-size: 18px;
    font-weight: 500;
    color: #333;
    margin: 0;
  }

  .delete-confirm-buttons {
    display: flex;
    justify-content: space-between;
    gap: 16px;
  }

  .cancel-button,
  .confirm-button {
    flex: 1;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .cancel-button {
    background: #f5f5f5;
  }

  .cancel-button:hover {
    background: #ebebeb;
  }

  .confirm-button {
    background: #ff4d4f;
    color: white;
  }

  .confirm-button:hover {
    background: #ff7875;
  }

  .cancel-button p,
  .confirm-button p {
    margin: 0;
    font-size: 15px;
    font-weight: 500;
  }

  /* 添加加载更多的样式 */
  .loading-more {
    text-align: center;
    padding: 16px;
    color: #666;
  }

  .loading-more p {
    font-size: 14px;
    margin: 0;
  }

  .password-input-container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .password-toggle {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0.8;  /* 提高默认透明度 */
    transition: all 0.2s ease;  /* 过渡效果应用到所有属性 */
    border-radius: 4px;  /* 添加圆角 */
    padding: 4px;  /* 增加可点击区域 */
  }

  .password-toggle:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.05);  /* 添加悬停背景色 */
    transform: translateY(-50%) scale(1.05);  /* 添加轻微放大效果 */
  }

  .password-toggle:active {
    transform: translateY(-50%) scale(0.95);  /* 添加点击效果 */
  }

  .eye-icon {
    width: 20px;
    height: 20px;
    filter: contrast(0.8) brightness(0.8);  /* 增加图标对比度和亮度 */
  }

  /* 修改密码输入框样式 */
  .user-password {
    padding-right: 44px;  /* 稍微增加右侧内边距 */
  }
  </style>
  